<template>
<div>
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
  <div class="banner" :style="{backgroundImage: 'url(' + getBgImgMessage() + ')', height: getHeight()}">
    <div class="wrap" :style="{height: getHeight()}">
      <div class="word-wrapper">
        <span class="title">留言</span> <br>
      </div>
    </div>
  </div>
  <!-- content -->
  <LVMessage v-bind:article_id="0"></LVMessage>
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
</div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Header from "../components/Header.vue"  ;
import Version from "../components/Version.vue"  ;
import LVMessage from "../components/LVMessage.vue"  ;
import * as sysTool from '../assets/systools.js' ; 
export default {
  name : 'Message' , 
  components:{Header1,Header,Version,LVMessage} , 
  data() {
    return {
      // 留言表单
      message: {
        visitorName: '',
        visitorEmail: '',
        content: '' , 
        visitorIp:'' , 
        visitorArea:'',
        parentId:'' , 
        browser: '',
        os: '' 
      },
      // 表单验证
      rules: {} , 
      // 留言items
      messageItem:[] ,
      // 显示回复框
      showDialog: false,  
      messageImg:""

    };
  },
  methods: {
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 提交留言
    submitForm(formName) {
      if (!this.message.content || !this.message.visitorName) {
        this.$message({
          message: "请填写昵称和内容",
          type: "warning"
        });
        return;
      }
      var os = sysTool.GetOs() ; 
      this.message.visitorIp = sessionStorage.getItem('ip') ; 
      this.message.visitorArea = sessionStorage.getItem('area') ; 
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      this.$axios.post('/addMessage' , this.message , {

      }).then(res => {
        this.getMessages() ; 
        this.$message.success("留言成功！") ; 
        this.message = {} ; 
      })
    },
    // 重置留言
    resetForm(formName) {
      this.$refs[formName].resetFields();
    } , 
    // 获取所有留言
    getMessages() {
      // 如果是留言的话，就写死=0
      // 如果是 评论，则需要设置 当前被评论的模块的id作为articleId
      let articleId = 0;
      this.$axios.get("/getMessages/" + articleId).then(res => {
        this.messageItem = res.data.data;
        this.messageItem.content
        // console.log(this.messageItem);
      })
    },
    // dialog取消
    cancel() {
      this.showDialog = false;
      this.message = {};
    },
    // 回复留言，将当前留言的id作为此条留言的父id
    reReply(id) {
      // console.log(id);
      this.showDialog = true;
      this.message.parentId = id;
    },
    // 保存回复
    save() {
      if (!this.message.content || !this.message.visitorName) {
        this.$message({
          message: "请填写昵称和内容",
          type: "warning"
        });
        return;
      }
      // console.log(this.message);
      // 如果是评论的话，在 save的时候要注意设置 当前模块的id为 foreignId。也就是  message.foreignId = 模块id
      this.message.visitorIp = sessionStorage.getItem('ip') ; 
      this.message.visitorArea = sessionStorage.getItem('area') ; 
      var os = sysTool.GetOs() ; 
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      this.$axios.post('/addMessage' , this.message , {

      }).then(res => {
        this.getMessages() ; 
        this.$message.success("回复成功！") ; 
        this.message = {}
        this.showDialog = false;
      })
    },
    getLogos(os,browser){
      let osLogo = '' ; 
      let browserLogo = ' ' ;
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      if (os === '') {
        
      }
    } , 
    // 获取留言的背景图片
    getMessageImg(){
      const _this = this ; 
      _this.$axios.get("/getBgMessage").then(res => {
        _this.messageImg = res.data.data ; 
        // return res.data.data ; 
      }) ; 
    }  , 
    getBgImgMessage(){
      const _this = this ; 
      return _this.messageImg ; 
    }
  } , 
  created(){
    // this.getMessages() ; 
    this.getMessageImg() ; 
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  /* background-image: url("../img/k-on1.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; */
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}



@media all and (min-width:850px) {
  .content{
    width: 55vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }

/* 表单区域 */
  .content .message_area{
    width: 98%;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 6rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 2rem;
    font-size: 1.2rem;
    border: 1px solid rgb(235, 232, 232);
    border-left: 3px solid rgb(75,174,194);
    border-right: 3px solid rgb(209, 63, 124);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
  .content .message_area .temp{
    display: flex;
  }
  .content .message_area .name,
  .content .message_area .email {
    width: 14.5vw;
  }
  .content .message_area .textarea{
    width: 33vw;
  }
  .liuyana{
    width: 33vw;
    background-color: rgb(82,191,213);
    background-image: linear-gradient(-45deg, rgb(82,191,213),rgb(82,213,128) ); 
    border: none;
  }

  .content .message_items .items_wrap .son_message{
    width: 42vw;
    background-color:rgba(255, 255, 255, 0.5);; 
    padding: 1rem ;
    border-radius: .5rem;
  }
  .content .message_items .items_wrap .message_bottom .cre_time{
    margin-left: 34vw;
  }

/* dialog区域 */

  .replayDialog{
    width: 60rem;
    position: fixed;
    top: 28vh;
    left: 35vw;
    z-index: 10;
    background: #fff;
    padding: 3rem;
    padding-bottom: 0;
    border-radius: .7rem;
  }
  .replayDialog .title{
    font-size: 1.6rem;
    font-weight: 550;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
  .replayDialog .dialog_form{
    margin-top: 2rem;
  }

}
@media all and (max-width:850px) {
  .content{
    width: 96vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }

/* 表单区域 */
  .content .message_area{
    width: 98%;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 6rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 2rem;
    font-size: 1.2rem;
    border: 1px solid rgb(235, 232, 232);
    border-left: 3px solid rgb(75,174,194);
    border-right: 3px solid rgb(209, 63, 124);
    box-sizing: border-box;
  }
  /* .content .message_area .temp{
    display: flex;
  } */
  .content .message_area .name,
  .content .message_area .email {
    width: 60vw;
  }
  .content .message_area .textarea{
    width: 60vw;
  }

  .content .message_items .items_wrap .item_content p {
    width: 70vw;
    word-wrap: break-word;
  }
  .content .message_items .items_wrap .son_message{
    width: 60vw;
    background-color: rgba(255, 255, 255, 0.5);; 
    padding: 1rem ;
    border-radius: .5rem;
    word-wrap: break-word;
  }
  .content .message_items .items_wrap .message_bottom .cre_time{
    margin-left: 8rem;
  }

/* dialog区域 */

  .replayDialog{
    width: 80vw;
    position: fixed;
    top: 15vh;
    left: 1.7vw;
    z-index: 10;
    background: #fff;
    padding: 3rem;
    padding-bottom: 0;
    border-radius: .7rem;
  }
  .replayDialog .title{
    font-size: 1.6rem;
    font-weight: 550;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
  .replayDialog .dialog_form{
    margin-top: 2rem;
  }
  .liuyana{
    width: 60vw;
    background-color: rgb(82,191,213);
    background-image: linear-gradient(-45deg, rgb(82,191,213),rgb(82,213,128) ); 
    border: none;
  }
}


/* message区域 */
  .content .message_items{
    width: 98%;
    display: flex; 
    /* padding: 20px ; */
    margin: 0 auto;
    margin-top: 3rem;
    padding-top: 3rem;
    font-size: 1.3rem;
    border-top: 1px solid #eee;
  }
  .content .message_items .avatar_wrap{
    text-align: center; 
    flex: 1 ;
  }
  .content .message_items .avatar_wrap .avatar{
    width: 6rem; 
    height: 6rem; 
    border-radius: 50% ;
  }
  .content .message_items .items_wrap{
    padding: 0 10px; 
    flex: 9 ;
  }
  .content .message_items .items_wrap .visitor_name{
    font-weight: 500;
    font-size: 1.4rem;
    color: rgb(82,191,213);
  }
  .content .message_items .items_wrap .item_content{
    padding: 10px 0; 
    color: rgb(119, 115, 115) ;
  }
  .content .message_items .items_wrap .message_bottom{
    margin-top: 1rem;
    color: #888; 
  }
  .content .message_items .items_wrap .message_bottom .replay_btn{
    cursor: pointer;
    transition: all .3s;
  }
  .content .message_items .items_wrap .message_bottom .replay_btn:hover{
    color: rgb(82,191,213);
  }

  .shadow{
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .el-button--primary{
    background-color: rgb(82,191,213);
    border: none;
  }
  .up{
    background-color: rgba(255, 255, 255, 0.5);  
  }
</style>